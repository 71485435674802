export default function Logo() {
  return (
    <svg  height='24' viewBox='0 0 61 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M48.0098 0L55.8398 19.2009L55.8478 0H59.6209V17.4414C59.6209 20.8674 56.8435 23.6448 53.4175 23.6448H52.3731L45.7228 4.44338L45.716 23.6448H41.7383V0H48.0098ZM45.716 23.645V23.6448H52.3731L52.3732 23.645H45.716Z'
        fill='#343434'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.3141 23.6446C33.5266 23.6446 37.7522 14.7931 37.7522 3.87426C37.7522 2.54832 37.6899 1.25286 37.5711 4.44535e-05H19.0571C18.9383 1.25286 18.876 2.54832 18.876 3.87426C18.876 14.7931 23.1016 23.6446 28.3141 23.6446ZM33.9776 0H22.8506V5.98888C22.8505 6.01073 22.8505 6.03259 22.8505 6.05447C22.8505 6.07635 22.8505 6.09821 22.8506 6.12006V6.15961H22.8507C22.8777 12.5341 25.3582 17.6841 28.414 17.6841C31.4699 17.6841 33.9503 12.5341 33.9773 6.15961H33.9776V0Z'
        fill='#343434'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.17013 17.2767V6.89108C5.1889 6.89096 5.20769 6.8909 5.22649 6.8909C8.88404 6.8909 11.8534 9.18683 11.8905 12.03V12.1377C11.8534 14.9809 8.88404 17.2769 5.22649 17.2769C5.20769 17.2769 5.1889 17.2768 5.17013 17.2767ZM1.03418 23.6445V0.000407539C1.07658 0.000136022 1.11901 0 1.16149 0C9.54085 0 16.3337 5.2931 16.3337 11.8225C16.3337 18.3518 9.54085 23.645 1.16149 23.645C1.11901 23.645 1.07658 23.6448 1.03418 23.6445Z'
        fill='#343434'
      />
    </svg>
  )
}
